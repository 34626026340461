<!-- copy-right-section -->
<footer class="cpoy-right-bg">
  <div class="container">
    <div class="row">
      <div class="col-md-12 text-center">
        <div class="social-footer">
          <ul>
            <!-- 
              <li><a href=""><i class="fa fa-facebook" aria-hidden="true"></i></a></li>
              <li><a href=""><i class="fa fa-google-plus" aria-hidden="true"></i></a></li>
            -->
              <li><a href="https://twitter.com/sayzencom"><i class="fa fa-twitter" aria-hidden="true"></i></a></li>
            <!-- 
              <li><a href=""><i class="fa fa-instagram" aria-hidden="true"></i></a></li>
              <li><a href=""><i class="fa fa-rss" aria-hidden="true"></i></a></li>
            -->
          </ul>
        </div>
      </div>
      <div class="col-md-12 text-center">
        <p class="copyright">2020-{{today | date:'y'}} Copyright by Sayzen.com, Inc proudly made in the U.S.A.</p>
      </div>
    </div>
  </div>
</footer>
<!--end copy right section-->
<!-- Tap to top -->
<div class="tap-top">
  <div>
    <i class="fa fa-angle-up" aria-hidden="true"></i>
  </div>
</div>
<!-- Tap to top end -->
