<!--Nav start-->
<nav class="navbar navbar-expand-lg  theme-nav">
    <div class="container">
        <a class="navbar-brand" [routerLink]="'/demo'"><img src="assets/images/logo.png" alt="logo"></a>
        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#mainmenu"  aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"><i class="fa fa-align-justify" aria-hidden="true"></i></span>
        </button>
        <div class="collapse navbar-collapse" id="mainmenu">
            <ul class="navbar-nav ml-auto" id="mymenu">
                <li class="nav-item ">
                    <a class="nav-link" [routerLink]="'/home/one'" >Home</a>
                </li>
                <li class="nav-item dropdown">
                    <a [routerLink]="'/home/one'" class="nav-link dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Blog</a>
                    <ul class="dropdown-menu">
                        <li class="nav-item"><a class="nav-link" [routerLink]="'/blog/list'">blog list</a></li>
                        <li class="nav-item"><a class="nav-link" [routerLink]="'/blog/details'">blog details</a></li>
                        <li class="nav-item"><a class="nav-link" [routerLink]="'/blog/left-sidebar'">leftsidebar</a></li>
                        <li class="nav-item"><a class="nav-link" [routerLink]="'/blog/right-sidebar'">rightsidebar</a></li>
                        <li class="nav-item"><a class="nav-link" [routerLink]="'/blog/details-left-sidebar'">details leftsidebar</a></li>
                        <li class="nav-item"><a class="nav-link" [routerLink]="'/blog/details-right-sidebar'">details rightsidebar</a></li>
                    </ul>
                </li>
                <li class="nav-item dropdown">
                    <a class="nav-link dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Other Page</a>
                    <ul class="dropdown-menu">
                        <li class="nav-item"><a class="nav-link" [routerLink]="'/pages/sign-in'">Sign In</a></li>
                        <li class="nav-item"><a class="nav-link" [routerLink]="'/pages/sign-up'">Sign Up</a></li>
                        <li class="nav-item"><a class="nav-link" [routerLink]="'/pages/forget-password'">Forget Password</a></li>
                        <li class="nav-item"><a class="nav-link" [routerLink]="'/pages/thank-you'">Thank You</a></li>
                        <li class="nav-item"><a class="nav-link" [routerLink]="'/pages/review'">Review</a></li>
                        <li class="nav-item"><a class="nav-link" [routerLink]="'/pages/faq'">FAQ</a></li>
                        <li class="nav-item"><a class="nav-link" [routerLink]="'/pages/coming-soon'">Coming Soon</a></li>
                        <li class="nav-item"><a class="nav-link" [routerLink]="'/pages/download'">Download</a></li>
                        <li class="nav-item"><a class="nav-link" [routerLink]="'/pages/request'">Request Demo</a></li>
                        <li class="nav-item"><a class="nav-link" [routerLink]="'/pages/404'">404</a></li>
                    </ul>
                </li>
            </ul>
        </div>
    </div>
</nav>
<!--Nav end -->

<!-- home section -->
<section class="theme-bg home">
    <div class="animation-circle-inverse">
        <i></i>
        <i></i>
        <i></i>
    </div>
    <div class="animation-circle-inverse absolute">
        <i></i>
        <i></i>
        <i></i>
    </div>
    <div class="container">
        <div class="row">
            <div class="col-md-6">
                <div class="home-contain">
                    <div class="fadeInLeft-land-caption">
                        <h2 class="landing-caption text-white">Best App Landing for all your needs.. !</h2>
                        <p class="text-white">Chatloop is perfect landing page for game , photoediting , browser or any mobile application landing page  </p>
                        <div>
                            <a [routerLink]="'/home/one'" class="btn btn-inverse mt-2 mr-3">Check Now</a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-6 right">
                <div class="home-right">
                    <div class="mobile-slid">
                        <img src="assets/images/demo/home/weightlifting.png" alt="top1" class="img-fluid">
                    </div>
                    <div class="photo">
                        <img src="assets/images/demo/home/photo-Editing-app.png" alt="top2" class="img-fluid">
                    </div>
                    <div class="gym">
                        <img src="assets/images/demo/home/gym-app.png" alt="top3" class="img-fluid">
                    </div>
                    <div class="game">
                        <img src="assets/images/demo/home/game-app.png" alt="top4" class="img-fluid">
                    </div>
                    <div class="msg">
                        <img src="assets/images/demo/home/messanger.png" alt="top5" class="img-fluid">
                    </div>
                    <div class="broweser">
                        <img src="assets/images/demo/home/browser.png" alt="top1" class="img-fluid">
                    </div>
                    <div class="love-emoji">
                        <img src="assets/images/top5.png" alt="top5" class="img-fluid">
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- home section end -->

<!-- home demo section -->
<section class="fadinup" id="home-demo">
    <div class="container-fluid m-width">
        <div class="row">
            <div class="col-md-12 text-center">
                <div class="section-title mb-2">
                    <h2>View Landing Page</h2>
                    <img src="assets/images/title-line.png" alt="title-line" class="img-fluid">
                </div>
            </div>
            <div class="col-lg-6 offset-lg-3 col-md-10 offset-md-1">
                <p class="demo-description">Clean and clear designs makes websites differ from others. We've pre-build demo for giving you as much as flexibility in making your site unique.</p>
            </div>
        </div>
        <div class="row mt-35 landing-screen-animation">
            <div class="col-xl-12 col-lg-12 col-12 text-center">
                <div>
                    <a [routerLink]="'/home/one'">
                        <span class="pc-bg">
                          <span style="background-image: url('assets/images/demo/ltr.jpg');" class="img-scroll"></span>
                      </span>
                    </a>
                </div>
                <h2 class="demo-title">Home Page</h2>
            </div>
        </div>
    </div>
</section>
<!-- home demo section end -->

<!-- other page section  -->
<section class="fadin-blog pt-0">
    <div class="container m-width">
        <div class="row">
            <div class="col-md-12 text-center">
                <div class="section-title mb-2">
                    <h2>Other Page</h2>
                    <img src="assets/images/title-line.png" alt="title-line" class="img-fluid">
                </div>
            </div>
        </div>
        <div class="row mt-35 pages-space">
            <div class="col-xl-4 col-lg-6 col-12 text-center">
                <div>
                    <a [routerLink]="'/blog/list'">
                <span class="pc-bg">
                  <span style="background-image: url('assets/images/demo/blog-list.jpg');" class="img-scroll"></span>
                </span>
                    </a>
                </div>
                <h2 class="demo-title">blog List</h2>
            </div>
            <div class="col-xl-4 col-lg-6 col-12 text-center">
                <div>
                    <a [routerLink]="'/blog/details'">
                <span class="pc-bg">
                  <span style="background-image: url('assets/images/demo/blog-details.jpg');" class="img-scroll"></span>
                </span>
                    </a>
                </div>
                <h2 class="demo-title">blog-details</h2>
            </div>
            <div class="col-xl-4 col-lg-6 col-12 text-center">
                <div>
                    <a [routerLink]="'/blog/left-sidebar'">
                <span class="pc-bg">
                  <span style="background-image: url('assets/images/demo/blog-list-left.jpg');" class="img-scroll"></span>
                </span>
                    </a>
                </div>
                <h2 class="demo-title">blog list with sidebar</h2>
            </div>
            <div class="col-xl-4 col-lg-6 col-12 text-center">
                <div>
                    <a [routerLink]="'/blog/right-sidebar'">
                <span class="pc-bg">
                  <span style="background-image: url('assets/images/demo/blog-list-right.jpg');" class="img-scroll"></span>
                </span>
                    </a>
                </div>
                <h2 class="demo-title">blog list with right sidebar</h2>
            </div>
            <div class="col-xl-4 col-lg-6 col-12 text-center">
                <div>
                    <a [routerLink]="'/blog/details-left-sidebar'">
                <span class="pc-bg">
                  <span style="background-image: url('assets/images/demo/blog-details-left-sidebar.jpg');" class="img-scroll"></span>
                </span>
                    </a>
                </div>
                <h2 class="demo-title">blog details with sidebar</h2>
            </div>
            <div class="col-xl-4 col-lg-6 col-12 text-center">
                <div>
                    <a [routerLink]="'/blog/details-right-sidebar'">
                <span class="pc-bg">
                  <span style="background-image: url('assets/images/demo/blog-details-right-sidebar.jpg');" class="img-scroll"></span>
                </span>
                    </a>
                </div>
                <h2 class="demo-title">blog details with right sidebar</h2>
            </div>
            <div class="col-xl-4 col-lg-6 col-12 text-center">
                <div>
                    <a [routerLink]="'/pages/sign-in'">
                <span class="pc-bg">
                  <span style="background-image: url('assets/images/demo/sign-in.jpg');" class="img-scroll"></span>
                </span>
                    </a>
                </div>
                <h2 class="demo-title">Sign In</h2>
            </div>
            <div class="col-xl-4 col-lg-6 col-12 text-center">
                <div>
                    <a [routerLink]="'/pages/sign-up'">
                <span class="pc-bg">
                  <span style="background-image: url('assets/images/demo/sign-up.jpg');" class="img-scroll"></span>
                </span>
                    </a>
                </div>
                <h2 class="demo-title">Sign Up</h2>
            </div>
            <div class="col-xl-4 col-lg-6 col-12 text-center">
                <div>
                    <a [routerLink]="'/pages/forget-password'">
                <span class="pc-bg">
                  <span style="background-image: url('assets/images/demo/forget.jpg');" class="img-scroll"></span>
                </span>
                    </a>
                </div>
                <h2 class="demo-title">Forget Password</h2>
            </div>
            <div class="col-xl-4 col-lg-6 col-12 text-center">
                <div>
                    <a [routerLink]="'/pages/coming-soon'">
                <span class="pc-bg">
                  <span style="background-image: url('assets/images/demo/comingsoon.jpg');" class="img-scroll"></span>
                </span>
                    </a>
                </div>
                <h2 class="demo-title">Coming Soon</h2>
            </div>
            <div class="col-xl-4 col-lg-6 col-12 text-center">
                <div>
                    <a [routerLink]="'/pages/request'">
                <span class="pc-bg">
                  <span style="background-image: url('assets/images/demo/request.jpg');" class="img-scroll"></span>
                </span>
                    </a>
                </div>
                <h2 class="demo-title">Request Schedule</h2>
            </div>
            <div class="col-xl-4 col-lg-6 col-12 text-center">
                <div>
                    <a [routerLink]="'/pages/review'">
                <span class="pc-bg">
                  <span style="background-image: url('assets/images/demo/review.jpg');" class="img-scroll"></span>
                </span>
                    </a>
                </div>
                <h2 class="demo-title">Review</h2>
            </div>
            <div class="col-xl-4 col-lg-6 col-12 text-center">
                <div>
                    <a [routerLink]="'/pages/download'">
                <span class="pc-bg">
                  <span style="background-image: url('assets/images/demo/download.jpg');" class="img-scroll"></span>
                </span>
                    </a>
                </div>
                <h2 class="demo-title">Download Page</h2>
            </div>
            <div class="col-xl-4 col-lg-6 col-12 text-center">
                <div>
                    <a [routerLink]="'/pages/faq'">
                <span class="pc-bg">
                  <span style="background-image: url('assets/images/demo/faq.jpg');" class="img-scroll"></span>
                </span>
                    </a>
                </div>
                <h2 class="demo-title">FAQ</h2>
            </div>
            <div class="col-xl-4 col-lg-6 col-12 text-center">
                <div>
                    <a [routerLink]="'/pages/404'" >
                <span class="pc-bg">
                  <span style="background-image: url('assets/images/demo/404.jpg');" class="img-scroll"></span>
                </span>
                    </a>
                </div>
                <h2 class="demo-title">404 Error Page</h2>
            </div>
            <div class="col-xl-4 col-lg-6 col-12 text-center offset-xl-4 offset-lg-0">
                <div>
                    <a [routerLink]="'/pages/thank-you'">
                <span class="pc-bg">
                  <span style="background-image: url('assets/images/demo/thankyou.jpg');" class="img-scroll"></span>
                </span>
                    </a>
                </div>
                <h2 class="demo-title">Thank You</h2>
            </div>
        </div>
    </div>
</section>
<!-- other page section end -->

<!-- main feature section -->
<section class="features-bg mb-0">
    <div class="container m-width">
        <div class="row">
            <div class="col-md-12">
                <div class="col-md-12 text-center">
                    <div class="section-title mb-2">
                        <h2>Features of chatloop</h2>
                        <img src="assets/images/title-line.png" alt="title-line" class="img-fluid">
                    </div>
                </div>
                <div class="border-shape"></div>
                <div class="mt-50">
                    <ul class="features m-0 p-0 fadeIn-features">
                        <li>
                            <div class="features-img">
                                <img src="assets/images/features/html5.png" class="img-fluid" alt="html5">
                                <h3 class="features-title">HTML 5</h3>
                            </div>
                        </li>
                        <li>
                            <div class="features-img">
                                <img src="assets/images/features/css-3.png" class="img-fluid" alt="css">
                                <h3 class="features-title">Css 3</h3>
                            </div>
                        </li>
                        <li>
                            <div class="features-img">
                                <img src="assets/images/features/sass.png" class="img-fluid" alt="Scss">
                                <h3 class="features-title">SCSS</h3>
                            </div>
                        </li>
                        <li>
                            <div class="features-img">
                                <img src="assets/images/features/ps.png" class="img-fluid" alt="PSD">
                                <h3 class="features-title">Free PSD</h3>
                            </div>
                        </li>
                        <li>
                            <div class="features-img">
                                <img src="assets/images/features/bootstrap-4.png" class="img-fluid" alt="bootstrap-4">
                                <h3 class="features-title">bootstrap 4</h3>
                            </div>
                        </li>
                        <li>
                            <div class="features-img">
                                <img src="assets/images/features/animate-css.png" class="img-fluid" alt="animate-css">
                                <h3 class="features-title">Animate css</h3>
                            </div>
                        </li>
                        <li>
                            <div class="features-img">
                                <img src="assets/images/features/responsive.png" class="img-fluid" alt="responsive">
                                <h3 class="features-title">Responsive</h3>
                            </div>
                        </li>
                        <li>
                            <div class="features-img">
                                <img src="assets/images/features/google-fonts-support.png" class="img-fluid" alt="Google-fonts">
                                <h3 class="features-title">Google fonts support</h3>
                            </div>
                        </li>
                        <li>
                            <div class="features-img">
                                <img src="assets/images/features/w3c-validation.png" class="img-fluid" alt="w3c-validation">
                                <h3 class="features-title">W3C Validation</h3>
                            </div>
                        </li>
                        <li>
                            <div class="features-img">
                                <img src="assets/images/features/pixel-perfect-design.png" class="img-fluid" alt="Pixel-Perfect">
                                <h3 class="features-title">Pixel Perfect Design</h3>
                            </div>
                        </li>
                        <li>
                            <div class="features-img">
                                <img src="assets/images/features/all-broweser-support.png" class="img-fluid" alt="browser-support">
                                <h3 class="features-title">all browser support </h3>
                            </div>
                        </li>
                        <li>
                            <div class="features-img">
                                <img src="assets/images/features/rtl.png" class="img-fluid" alt="rtl">
                                <h3 class="features-title">rtl <span class="text-success">(Coming soon)</span></h3>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- main feature section end -->

<!-- template section  -->
<section class="theme-bg pt-70 m-0 pb-0 grab">
    <div class="container m-width">
        <div class="row">
            <div class="col-xl-5 offset-xl-1 col-md-6 ">
                <div class="tamp-d-flex">
                    <div>
                        <h2 class="tapl-title fadeInLeft-land-caption mb-5">Stop thinking, Just grab it</h2>
                        <a [routerLink]="'/home/one'"  class=" btn btn-inverse">Download</a>
                    </div>
                </div>
            </div>
            <div class="col-xl-5 offset-xl-1 col-md-6 ">
                <div class="home-right grab-img">
                    <div class="mobile-slid">
                        <img src="assets/images/top-mobile.png" alt="top1" class="img-fluid">
                    </div>
                    <div class="profile-msg">
                        <img src="assets/images/top2.png" alt="top2" class="img-fluid">
                    </div>
                    <div class="awesome">
                        <img src="assets/images/top3.png" alt="top3" class="img-fluid">
                    </div>
                    <div class="emoji">
                        <img src="assets/images/top5.png" alt="top5" class="img-fluid">
                    </div>
                    <div class="profile-2">
                        <img src="assets/images/top1.png" alt="top1" class="img-fluid">
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- template section end -->

<!-- Rate template section  -->
<section>
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-12 text-center">
                <div class="tamp-d-flex">
                    <div>
                        <p class="coming-title rating-title">If you love this template please rate us</p>
                        <div class="rating-star">
                            <img src="assets/images/star.png" alt="star">
                            <img src="assets/images/star.png" alt="star">
                            <img src="assets/images/star.png" alt="star">
                            <img src="assets/images/star.png" alt="star">
                            <img src="assets/images/star.png" alt="star">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- Rate template section end -->

<!--Footer Section start-->
<div class="bg-light">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <div class="Copyright-section">
                    <p>{{today | date:'y'}} Copyright &copy; powered by Pixelstrap</p>
                </div>
            </div>
        </div>
    </div>
</div>
<!--Footer Section End-->

<!--[if lt IE 9]>
<div class="ie-warning">
    <h1>Warning!!</h1>
    <p>You are using an outdated version of Internet Explorer, please upgrade <br/>to any of the following web browsers to access this website.</p>
    <div class="iew-container">
        <ul class="iew-download">
            <li>
                <a href="http://www.google.com/chrome/">
                    <img src="assets/images/browsers/chrome.png" alt="">
                    <div>Chrome</div>
                </a>
            </li>
            <li>
                <a href="https://www.mozilla.org/en-US/firefox/new/">
                    <img src="assets/images/browsers/firefox.png" alt="">
                    <div>Firefox</div>
                </a>
            </li>
            <li>
                <a href="http://www.opera.com">
                    <img src="assets/images/browsers/opera.png" alt="">
                    <div>Opera</div>
                </a>
            </li>
            <li>
                <a href="https://www.apple.com/safari/">
                    <img src="assets/images/browsers/safari.png" alt="">
                    <div>Safari</div>
                </a>
            </li>
            <li>
                <a href="http://windows.microsoft.com/en-us/internet-explorer/download-ie">
                    <img src="assets/images/browsers/ie.png" alt="">
                    <div>IE (9 & above)</div>
                </a>
            </li>
        </ul>
    </div>
    <p>Sorry for the inconvenience!</p>
</div>
<![endif]-->