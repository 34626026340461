<!--Nav start-->
<nav class="navbar navbar-expand-lg  theme-nav fixed-top" [class.darkHeader]="darkHeader">
  <div class="container">
    <a  class="navbar-brand" [routerLink]="'/'"><img src="assets/images/sayzen40_FINAL.png" alt="logo"></a>
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#mainmenu" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"><i class="fa fa-align-justify" aria-hidden="true"></i></span>
    </button>
    <div class="collapse navbar-collapse" id="mainmenu">
      <ul class="navbar-nav ml-auto" id="mymenu">
        <li class="nav-item ">
          <a class="nav-link" href="#home" [pageScrollOffset]="75" pageScroll>Home</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="#about" [pageScrollOffset]="75" pageScroll>about</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="#feature" [pageScrollOffset]="75" pageScroll>Features</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="#work" [pageScrollOffset]="75" pageScroll>How To</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="#package" [pageScrollOffset]="75" pageScroll>pricing</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="#faq" [pageScrollOffset]="75" pageScroll>faq</a>
        </li>
        <!-- 
        <li class="nav-item dropdown">
          <a href="#news" class="nav-link dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" [pageScrollOffset]="75" pageScroll>Blog</a>
          <ul class="dropdown-menu">
            <li class="nav-item"><a class="nav-link" [routerLink]="'/blog/list'" [routerLinkActive]="'active'" [routerLinkActiveOptions]="{exact: true}">blog list</a></li>
            <li class="nav-item"><a class="nav-link" [routerLink]="'/blog/details'" [routerLinkActive]="'active'" [routerLinkActiveOptions]="{exact: true}">blog details</a></li>
            <li class="nav-item"><a class="nav-link" [routerLink]="'/blog/left-sidebar'" [routerLinkActive]="'active'" [routerLinkActiveOptions]="{exact: true}">leftsidebar</a></li>
            <li class="nav-item"><a class="nav-link" [routerLink]="'/blog/right-sidebar'" [routerLinkActive]="'active'" [routerLinkActiveOptions]="{exact: true}">rightsidebar</a></li>
            <li class="nav-item"><a class="nav-link" [routerLink]="'/blog/details-left-sidebar'" [routerLinkActive]="'active'" [routerLinkActiveOptions]="{exact: true}">details leftsidebar</a></li>
            <li class="nav-item"><a class="nav-link" [routerLink]="'/blog/details-right-sidebar'" [routerLinkActive]="'active'" [routerLinkActiveOptions]="{exact: true}">details rightsidebar</a></li>
          </ul>
        </li>
        -->
        <li class="nav-item">
          <a class="nav-link" href="#contact" [pageScrollOffset]="75" pageScroll>contact us</a>
        </li>
        <!-- 
        <li class="nav-item dropdown">
          <a class="nav-link dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Other Page</a>
          <ul class="dropdown-menu">
            <li class="nav-item"><a class="nav-link" [routerLink]="'/pages/sign-in'">Sign In</a></li>
            <li class="nav-item"><a class="nav-link" [routerLink]="'/pages/sign-up'">Sign Up</a></li>
            <li class="nav-item"><a class="nav-link" [routerLink]="'/pages/forget-password'">Forget Password</a></li>
            <li class="nav-item"><a class="nav-link" [routerLink]="'/pages/thank-you'">Thank You</a></li>
            <li class="nav-item"><a class="nav-link" [routerLink]="'/pages/review'" [routerLinkActive]="'active'" [routerLinkActiveOptions]="{exact: true}">Review</a></li>
            <li class="nav-item"><a class="nav-link" [routerLink]="'/pages/faq'" [routerLinkActive]="'active'" [routerLinkActiveOptions]="{exact: true}">FAQ</a></li>
            <li class="nav-item"><a class="nav-link" [routerLink]="'/pages/coming-soon'">Coming Soon</a></li>
            <li class="nav-item"><a class="nav-link" [routerLink]="'/pages/download'">Download</a></li>
            <li class="nav-item"><a class="nav-link" [routerLink]="'/pages/request'">Request Demo</a></li>
            <li class="nav-item"><a class="nav-link" [routerLink]="'/pages/404'">404</a></li>
          </ul>
        </li>
        -->
      </ul>
    </div>
  </div>
</nav>
<!--Nav end -->
